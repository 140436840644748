import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, Autoplay } from 'swiper';
import classNames from 'classnames';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import styles from './Carousel.module.scss';

export interface ICarouselProps extends SwiperProps {
}

export const Carousel: React.FC<ICarouselProps> = ({
  className, modules, ...restProps
}) => (
  <Swiper
    speed={1000}
    className={classNames(styles.carousel, className)}
    modules={[Pagination, Navigation, Autoplay, ...(modules || [])]}
    {...restProps}
  />
);

export const CarouselItem = SwiperSlide;
