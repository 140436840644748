import React, { AnchorHTMLAttributes } from 'react';
import classNames from 'classnames';
import Link from 'next/link';
import { Spinner } from '../loaders/Spinner/Spinner';
import styles from './Button.module.scss';

export enum ButtonColor {
  primary = 'primary',
  secondary = 'secondary',
  tertiary = 'tertiary',
}

export interface IButtonProps extends AnchorHTMLAttributes<HTMLElement> {
  className?: string;
  color?: ButtonColor;
  type?: 'submit' | 'button';
  fullWidth?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
  nativeLink?: boolean;
}

export const Button = React.forwardRef<HTMLAnchorElement & HTMLButtonElement, IButtonProps>(({
  className,
  color = ButtonColor.primary,
  type = 'button',
  href,
  fullWidth,
  isLoading,
  disabled,
  nativeLink,
  children,
  ...restProps
}, ref) => {
  const generalProps = {
    ...restProps,
    className: classNames(
      {
        [styles.btnPrimary]: color === ButtonColor.primary,
        [styles.btnSecondary]: color === ButtonColor.secondary,
        [styles.btnTertiary]: color === ButtonColor.tertiary,
        [styles.fullWidth]: fullWidth,
        [styles.disabled]: disabled || isLoading,
        [styles.loading]: isLoading,
      },
      className,
    ),
    ref,
  };

  const content = (
    <>
      { children }
      { isLoading && (
        <Spinner className={styles.spinner} />
      ) }
    </>
  );

  if (href) {
    if (nativeLink) {
      return (
        <a {...generalProps} href={href}>
          { content }
        </a>
      );
    }

    return (
      <Link href={href}>
        <a {...generalProps}>
          { content }
        </a>
      </Link>
    );
  }

  return (
    <button
      {...generalProps}
      type={type}
      disabled={disabled || isLoading}
    >
      { content }
    </button>
  );
});
