import React from 'react';
import classNames from 'classnames';
import { Link, LinkColor } from '../Link/Link';
import IconPhone from '../../public/svg/phone.svg';
import styles from './ContactNumberLink.module.scss';

interface IContactNumberLinkProps {
  className?: string;
  phoneNumber: string;
}

export const ContactNumberLink: React.FC<IContactNumberLinkProps> = ({ className, phoneNumber }) => (
  <Link
    className={classNames(styles.contactNumber, className)}
    href={`tel:${phoneNumber}`}
    color={LinkColor.secondary}
  >
    <IconPhone className={styles.phoneIcon} />
    { phoneNumber }
  </Link>
);
