import { useRouter as useNextRouter } from 'next/router';
import { useCallback, useMemo } from 'react';
import queryString from 'query-string';

const QUERY_PARAMS_ARRAY_FORMAT = 'bracket';

export const useRouter = () => {
  const router = useNextRouter();
  const { asPath } = router;
  const [routePath, search] = useMemo(() => (
    asPath.split('?')
  ), [asPath]);

  const queryParams = useMemo(() => queryString.parse(search, {
    arrayFormat: QUERY_PARAMS_ARRAY_FORMAT,
    parseNumbers: true,
  }), [search]);

  const getHrefWithMergedQueryParams = useCallback((newQueryParams: Record<string, any>) => {
    const newSearch = queryString.stringify({
      ...queryParams,
      ...newQueryParams,
    }, {
      arrayFormat: QUERY_PARAMS_ARRAY_FORMAT,
    });

    return `${routePath}?${newSearch}`;
  }, [routePath, queryParams]);

  const mergeQueryParams = useCallback((newQueryParams: Record<string, any>) => {
    const href = getHrefWithMergedQueryParams(newQueryParams);

    router.push(href, undefined, { shallow: true });
  }, [getHrefWithMergedQueryParams]);

  return {
    ...router,
    routePath,
    queryParams,
    mergeQueryParams,
    getHrefWithMergedQueryParams,
  };
};
