import React from 'react';
import classNames from 'classnames';
import { ILinkProps, Link } from '../Link/Link';
import styles from './DetailsLink.module.scss';

export interface IDetailsLinkProps extends Partial<ILinkProps> {
}

export const DetailsLink: React.FC<IDetailsLinkProps> = ({
  className,
  children,
  ...restProps
}) => (
  <Link
    className={classNames(styles.detailsLink, className)}
    {...restProps}
  >
    { children }
    <div className={styles.figure} />
  </Link>
);
