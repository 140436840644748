import React, { HTMLAttributes, useCallback } from 'react';
import classNames from 'classnames';
import NextLink from 'next/link';
import { useRouter } from '../../hooks/useRouter';
import styles from './Link.module.scss';

export enum LinkColor {
  primary = 'primary',
  secondary = 'secondary',
  tertiary = 'tertiary',
  quaternary = 'quaternary',
}

export interface ILinkProps extends Partial<HTMLAttributes<HTMLAnchorElement>> {
  className?: string;
  activeClassName?: string;
  color?: LinkColor;
  href?: string;
  nativeElement?: boolean;
  preventDefault?: boolean;
  shallow?: boolean;
}

export const Link: React.FC<ILinkProps> = ({
  className,
  activeClassName,
  color = LinkColor.primary,
  href = '',
  nativeElement,
  preventDefault,
  shallow = true,
  children,
  onClick = () => {},
  ...restProps
}) => {
  const { routePath } = useRouter();

  const handleClick = useCallback((event: React.MouseEvent<HTMLAnchorElement>) => {
    if (preventDefault) {
      event.preventDefault();
    }

    onClick(event);
  }, [preventDefault, onClick]);

  const isActive = href === routePath;

  const commonProps = {
    ...restProps,
    className: classNames(
      {
        [styles.linkPrimary]: color === LinkColor.primary,
        [styles.linkSecondary]: color === LinkColor.secondary,
        [styles.linkTertiary]: color === LinkColor.tertiary,
        [styles.linkQuaternary]: color === LinkColor.quaternary,
        [activeClassName as string]: isActive,
      },
      className,
    ),
    onClick: handleClick,
  };

  if (nativeElement) {
    return (
      <a {...commonProps} href={href}>
        { children }
      </a>
    );
  }

  return (
    <NextLink href={href} shallow={shallow}>
      <a {...commonProps}>
        { children }
      </a>
    </NextLink>
  );
};
