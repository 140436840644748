import React from 'react';
import classNames from 'classnames';
import styles from './Spinner.module.scss';

interface ISpinnerProps {
  className?: string;
}

export const Spinner: React.FC<ISpinnerProps> = ({ className }) => (
  <div className={classNames(styles.spinner, className)}>
    <svg className={styles.circle} viewBox="25 25 50 50">
      <circle
        className={styles.circlePath}
        cx="50"
        cy="50"
        r="20"
        fill="none"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </svg>
  </div>
);
