import { IAppReducerStoreSlice } from '../types';

export const appReducerSelector = ({ appReducer }: IAppReducerStoreSlice) => appReducer;

export const brandsSelector = ({ appReducer }: IAppReducerStoreSlice) => appReducer.brands;

export const brandsCarouselItemsSelector = (state: IAppReducerStoreSlice) => {
  const { slugs, entities } = brandsSelector(state);

  return slugs.map((slug) => entities[slug]);
};
