import React, { useCallback } from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { Button, ButtonColor } from '../Button/Button';
import IconCity from '../../public/svg/location.svg';
import { CityCode, ICities } from '../../types/city';
import { setActiveCity } from '../../features/app/state/actions';
import styles from './CitySelection.module.scss';

export interface ICitySelectionProps {
  className?: string;
  title: string;
  cities: ICities;
  onCityClick?: () => void;
}

export const CitySelection: React.FC<ICitySelectionProps> = ({
  className, title, cities, onCityClick,
}) => {
  const dispatch = useDispatch();

  const handleSetActiveCity = useCallback((cityCode: CityCode) => {
    dispatch(setActiveCity({ cityCode }));

    if (onCityClick) {
      onCityClick();
    }
  }, [onCityClick]);

  return (
    <div className={classNames(styles.citySelection, className)}>
      <h3>{ title }</h3>
      <div className={styles.selection}>
        { cities.map(({ id, cityName, cityCode }) => (
          <Button
            key={id}
            className={styles.selectionBtn}
            color={ButtonColor.tertiary}
            onClick={() => handleSetActiveCity(cityCode)}
          >
            <IconCity className={styles.icon} />
            { cityName }
          </Button>
        )) }
      </div>
    </div>
  );
};
